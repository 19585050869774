import React from 'react'
import '../components/Styles/gridlex.scss'
import '../components/Styles/global.css' 

import NovaLayout from '../components/NovaLayout'
// import { PageHeader } from '../components/PageHeader'
import HubspotForm from 'react-hubspot-form'


const Index = () => {
  return (
    <NovaLayout title="Contact Us | Nova Digital Agency"  description="A digital agency designed for growth-driven mortgage businesses">
      <div className="layout-body">
        <div className="pt-2 xs-pt-2">
        <h4 className="sm-center xs-center mb-3 xs-mb-1">Thank for subscribing!</h4>
          <div className="grid">
            <div className="hubspot-contact-form">
              <HubspotForm
                portalId='6296607'
                formId='1a0d53b0-5e6c-45fe-88d6-e83c130fee6a'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
              />
            </div>
          </div>
        </div>
      </div>
      </NovaLayout>
  )
}

export default Index
